.App {
  text-align: center;
}

.home-main{
  position: absolute;
  top: 0;
  margin-left: 16rem;
  width: calc(100% - 16rem);
}
.ql-header.ql-picker{
  display: none !important;
}
.toolbar button .ql-stroke{
  stroke: white;
}
.toolbar select{
  display: inline-block !important;
  background-color: #333;
  border: 2px solid white;
    border-radius: 5px;
    padding: 3px 8px;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

h2 {
  margin: 0;
}

ul li {
  margin-bottom: 10px;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
